<template>
	<div class="flex w-fit items-center rounded-xl text-white-0 p-1 text-sm px-2"
	     :class="{
					'bg-green-400': props.server.status?.uptime,
					'bg-red-300': !props.server.status?.uptime
				 }">
		<div class="online-players-cube"></div>
		{{
			props.server.status?.uptime ?
					(props.server.status.players.online ?? 0) + "/" + props.server.status.players.max +
					" ONLINE" :
					"OFFLINE"
		}}
	</div>
</template>

<script setup>
const props = defineProps({
	server: {
		type: Object,
		required: true,
	},
});
</script>